.App {
  text-align: center;
  background-color: #121212;
  min-height: 99vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 1.5vh;
  padding-bottom: 1vh;
}

div {
  display: flex;
  flex-direction: column;
}

header {
  height: 10vh;
  background-color: #212121;
  width: 100vw;
  font-size: 2vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  margin-bottom: 3vh;
  top: 0;
}

.leaderboard-item-container {
  align-items: center;
}

.leaderboard {
  justify-content: center;
  font-size: calc(10px + 2vmin);
  align-items: center;
  width: 100vw;
  margin-bottom: 1vh;
  margin-top:13vh;
}

.divider {
  height: 5px;
  width: 70vw;
  background-color: #c70d3a;
}

.leaderboard-item {
  display: flex;
  height: 8vh;
  flex-direction: row;
  width: 70vw;
  border: 1px solid #121212;
  background-color: #191a19;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}

.leaderboard-place {
  flex: 1;
  font-size: calc(20px + 3vmin);
}

.leaderboard-name{
  flex: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.leaderboard-points{
  text-align: left;
  flex: 2;
}

.epa-item{
  flex: 2;
  text-align: "center"
}

.epa-item-title{
  font-size: large;
}

.epa-item-data{
  font-size: medium
}

.epa-item-container {
  display: flex;
  /* height: 8vh; */
  flex-direction: row;
  width: 90vw;
  border: 1px solid #121212;
  background-color: #191a19;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}